/**-----------------------------------------------------------------------------------------
* Copyright © 2020 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import { __decorate, __metadata, __param } from 'tslib';
import { Injectable, NgModule, Input, ViewChild, ElementRef, HostBinding, Directive, Optional, TemplateRef, ContentChild, Component, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, NgZone, ContentChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizeSensorModule, ResizeSensorComponent } from '@progress/kendo-angular-common';
export { ResizeSensorComponent } from '@progress/kendo-angular-common';
import { IntlService } from '@progress/kendo-angular-intl';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { exportImage, exportSVG, drawDOM } from '@progress/kendo-drawing';
import { validatePackage } from '@progress/kendo-licensing';
import { ArcGauge, LinearGauge, RadialGauge, CircularGauge } from '@progress/kendo-charts';

function isObject(value) {
    return typeof value === "object";
}
function diffOptions(original, current) {
    if (Object.keys(original).length !== Object.keys(current).length) {
        return true;
    }
    for (let field in original) {
        if (field !== 'value' && original.hasOwnProperty(field)) {
            const originalValue = original[field];
            const currentValue = current[field];
            const diff = isObject(originalValue) && isObject(currentValue) ?
                diffOptions(originalValue, currentValue) : originalValue !== currentValue;
            if (diff) {
                return true;
            }
        }
    }
}
function diffPointerOptions(original, current) {
    if (!original || !current) {
        return true;
    }
    original = [].concat(original);
    current = [].concat(current);
    if (original.length !== current.length) {
        return true;
    }
    for (let idx = 0; idx < original.length; idx++) {
        if (diffOptions(original[idx], current[idx])) {
            return true;
        }
    }
}
/**
 * @hidden
 */
let ConfigurationService = class ConfigurationService {
    /**
     * @hidden
     */
    constructor() {
        this.options = {};
    }
    copyChanges(prefix, changes) {
        for (let propertyName in changes) {
            if (!changes.hasOwnProperty(propertyName)) {
                continue;
            }
            const value = changes[propertyName].currentValue;
            const optionName = (prefix ? prefix + '.' : '') + propertyName;
            this.set(optionName, value);
        }
    }
    read() {
        this.hasChanges = false;
        this.valueChange = false;
        return this.options;
    }
    readValues() {
        this.valueChange = false;
        const pointers = [].concat(this.options.pointer);
        return pointers.map((pointer) => pointer.value);
    }
    readValue() {
        this.valueChange = false;
        return this.options.value;
    }
    set(field, value) {
        const { key, options } = this.optionContext(field);
        if (!this.hasChanges && (key === 'value' || (key === 'pointer' && !diffPointerOptions(this.options.pointer, value)))) {
            this.valueChange = true;
        }
        else {
            this.hasChanges = true;
            this.valueChange = false;
        }
        options[key] = value;
    }
    optionContext(field) {
        const parts = field.split('.');
        let options = this.options;
        let key = parts.shift();
        while (parts.length > 0) {
            options = options[key] = options[key] || {};
            key = parts.shift();
        }
        return { key: key, options: options };
    }
};
ConfigurationService = __decorate([
    Injectable()
], ConfigurationService);

/**
 * @hidden
 */
let CollectionChangesService = class CollectionChangesService {
};
CollectionChangesService = __decorate([
    Injectable()
], CollectionChangesService);

const template = `
    <div class="k-var--gauge-pointer"></div>
    <div class="k-var--gauge-track"></div>
    <div class="k-var--normal-text-color"></div>
`;
/**
 * @hidden
 */
let ThemeService = class ThemeService {
    read() {
        if (!this.options) {
            this.load();
        }
        return this.options;
    }
    load() {
        if (typeof document === 'undefined') {
            this.options = {};
            return;
        }
        const container = document.createElement('div');
        container.style.display = 'none';
        container.innerHTML = template;
        document.body.appendChild(container);
        try {
            const pointerColor = this.getColor(container, 'gauge-pointer');
            const rangePlaceholder = this.getColor(container, 'gauge-track');
            const textColor = this.getColor(container, 'normal-text-color');
            this.options = {
                pointer: {
                    color: pointerColor
                },
                scale: {
                    labels: {
                        color: textColor
                    },
                    rangePlaceholderColor: rangePlaceholder,
                    minorTicks: {
                        color: textColor
                    },
                    majorTicks: {
                        color: textColor
                    },
                    line: {
                        color: textColor
                    }
                }
            };
        }
        finally {
            document.body.removeChild(container);
        }
    }
    getColor(container, varName) {
        return window.getComputedStyle(container.querySelector(`.k-var--${varName}`)).backgroundColor;
    }
};
ThemeService = __decorate([
    Injectable()
], ThemeService);

/**
 * @hidden
 */
let SharedModule = class SharedModule {
};
SharedModule = __decorate([
    NgModule({
        exports: [ResizeSensorModule],
        providers: [
            ThemeService
        ]
    })
], SharedModule);

/**
 * @hidden
 */
class CollectionComponent {
    constructor(key, configurationService, collectionChangesService) {
        this.key = key;
        this.configurationService = configurationService;
        this.collectionChangesService = collectionChangesService;
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.configurationService.set(this.key, []);
    }
    ngAfterContentInit() {
        this.subscription = this.children.changes.subscribe(() => this.collectionChangesService.hasChanges = true);
    }
    ngAfterContentChecked() {
        if (this.collectionChangesService.hasChanges) {
            this.configurationService.set(this.key, this.readItems());
            this.collectionChangesService.hasChanges = false;
        }
    }
    readItems() {
        return this.children.map(child => Object.assign({}, child.configurationService.read()));
    }
}

/**
 * @hidden
 */
class CollectionItemComponent {
    constructor(configurationService, collectionChangesService) {
        this.configurationService = configurationService;
        this.collectionChangesService = collectionChangesService;
    }
    ngOnChanges(changes) {
        this.configurationService.copyChanges('', changes);
        this.collectionChangesService.hasChanges = true;
    }
}

/**
 * @hidden
 */
const packageMetadata = {
    name: '@progress/kendo-angular-gauges',
    productName: 'Kendo UI for Angular',
    productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
    publishDate: 1620213938,
    version: '',
    licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/?utm_medium=product&utm_source=kendoangular&utm_campaign=kendo-ui-angular-purchase-license-keys-warning'
};

const inDocument = (element) => {
    let node = element;
    while (node && node !== document.body) {
        node = node.parentNode;
    }
    return Boolean(node);
};
/**
 * @hidden
 */
class GaugeComponent {
    constructor(configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
        this.configurationService = configurationService;
        this.themeService = themeService;
        this.intlService = intlService;
        this.localizationService = localizationService;
        this.element = element;
        this.renderer = renderer;
        this.ngZone = ngZone;
        /**
         * The maximum number of times the Gauge resizes per second.
         * Defaults to `10`. To disable the automatic resizing, set `resizeRateLimit` to `0`.
         */
        this.resizeRateLimit = 10;
        this.className = true;
        this.theme = null;
        this.rtl = false;
        validatePackage(packageMetadata);
    }
    ngOnInit() {
        this.setDirection();
        this.subscriptions = this.intlService.changes.subscribe(this.intlChange.bind(this));
        this.subscriptions.add(this.localizationService.changes.subscribe(this.rtlChange.bind(this)));
    }
    ngAfterViewChecked() {
        if (typeof document === 'undefined') {
            return;
        }
        let updateMethod;
        if (!this.instance) {
            updateMethod = this.init;
        }
        else if (this.configurationService.hasChanges) {
            updateMethod = this.updateOptions;
        }
        else if (this.configurationService.valueChange) {
            updateMethod = this.setValues;
        }
        if (updateMethod) {
            clearTimeout(this.redrawTimeout);
            if (!this.instance && !inDocument(this.element.nativeElement)) { // required in case the gauge is initialized by ng-content outside of the DOM
                this.defer(() => {
                    this.updateCall(updateMethod);
                });
            }
            else {
                this.updateCall(updateMethod);
            }
        }
    }
    updateCall(updateMethod) {
        this.updateDirection();
        updateMethod.call(this);
        this.updateSize();
    }
    updateOptions() {
        this.instance.setOptions(this.configurationService.read());
    }
    setValues() {
        this.instance.allValues(this.configurationService.readValues());
    }
    ngOnChanges(changes) {
        this.configurationService.copyChanges('', changes);
    }
    ngOnDestroy() {
        if (this.instance) {
            this.instance.destroy();
        }
        this.subscriptions.unsubscribe();
        clearTimeout(this.redrawTimeout);
    }
    /**
     * Exports the Gauge as an image. The export operation is asynchronous and returns a promise.
     *
     * @param {ImageExportOptions} options - The parameters for the exported image.
     * @returns {Promise<string>} - A promise that will be resolved with a PNG image that is encoded as a Data URI.
     */
    exportImage(options = {}) {
        return this.exportVisual().then((visual) => {
            return exportImage(visual, options);
        });
    }
    /**
     * Exports the Gauge as an SVG document. The export operation is asynchronous and returns a promise.
     *
     * @param {SVGExportOptions} options - The parameters for the exported file.
     * @returns {Promise<string>} - A promise that will be resolved with an SVG document that is encoded as a Data URI.
     */
    exportSVG(options = {}) {
        return this.exportVisual().then((visual) => {
            return exportSVG(visual, options);
        });
    }
    /**
     * Exports the Gauge as a Drawing `Scene`.
     *
     * @returns {Promise<Group>} - A promise that will be resolved with the export visual.
     */
    exportVisual() {
        return Promise.resolve(this.instance.exportVisual());
    }
    /**
     * @hidden
     */
    onResize(_event) {
        if (this.autoResize) {
            this.resize();
        }
    }
    /**
     * Detects the size of the container and redraws the Gauge.
     * Resizing is automatic unless you set the `resizeRateLimit` option to `0`.
     */
    resize() {
        if (this.instance) {
            this.instance.resize();
        }
    }
    init() {
        if (!this.surfaceElement) {
            return;
        }
        this.createInstance(this.surfaceElement.nativeElement, this.configurationService.read(), this.themeService.read(), {
            intlService: this.intlService,
            rtl: this.rtl
        });
    }
    get autoResize() {
        return this.resizeRateLimit > 0;
    }
    updateSize() {
        this.resizeSensor.acceptSize();
    }
    intlChange() {
        if (this.instance) {
            this.deferredRedraw();
        }
    }
    rtlChange() {
        if (this.instance && this.rtl !== this.isRTL) {
            this.deferredRedraw();
        }
    }
    deferredRedraw() {
        this.defer(() => {
            this.updateDirection();
            this.instance.noTransitionsRedraw();
        });
    }
    defer(callback) {
        this.ngZone.runOutsideAngular(() => {
            clearTimeout(this.redrawTimeout);
            this.redrawTimeout = setTimeout(callback, 0);
        });
    }
    updateDirection() {
        const current = this.isRTL;
        if (this.rtl !== current) {
            this.setDirection();
            if (this.instance) {
                this.instance.setDirection(current);
            }
        }
    }
    setDirection() {
        this.rtl = this.isRTL;
        if (this.element) {
            this.renderer.setAttribute(this.element.nativeElement, 'dir', this.rtl ? 'rtl' : 'ltr');
        }
    }
    get isRTL() {
        return Boolean(this.localizationService.rtl);
    }
}
__decorate([
    Input(),
    __metadata("design:type", Object)
], GaugeComponent.prototype, "gaugeArea", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], GaugeComponent.prototype, "renderAs", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], GaugeComponent.prototype, "resizeRateLimit", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GaugeComponent.prototype, "scale", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], GaugeComponent.prototype, "transitions", void 0);
__decorate([
    ViewChild('surface', { static: true }),
    __metadata("design:type", ElementRef)
], GaugeComponent.prototype, "surfaceElement", void 0);
__decorate([
    ViewChild(ResizeSensorComponent, { static: true }),
    __metadata("design:type", ResizeSensorComponent)
], GaugeComponent.prototype, "resizeSensor", void 0);
__decorate([
    HostBinding('class.k-gauge'),
    __metadata("design:type", Boolean)
], GaugeComponent.prototype, "className", void 0);

/**
 * @hidden
 */
class SettingsComponent {
    constructor(key, configurationService) {
        this.key = key;
        this.configurationService = configurationService;
    }
    ngOnChanges(changes) {
        this.configurationService.copyChanges(this.key, changes);
    }
    ngOnDestroy() {
        this.configurationService.set(this.key, null);
    }
}

/**
 * @hidden
 */
class GaugeAreaComponent extends SettingsComponent {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], GaugeAreaComponent.prototype, "background", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GaugeAreaComponent.prototype, "border", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], GaugeAreaComponent.prototype, "height", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], GaugeAreaComponent.prototype, "margin", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], GaugeAreaComponent.prototype, "width", void 0);

/**
 * @hidden
 */
class LabelsComponent extends SettingsComponent {
}
__decorate([
    Input(),
    __metadata("design:type", String)
], LabelsComponent.prototype, "background", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LabelsComponent.prototype, "border", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LabelsComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LabelsComponent.prototype, "font", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LabelsComponent.prototype, "format", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LabelsComponent.prototype, "margin", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LabelsComponent.prototype, "padding", void 0);
__decorate([
    Input(),
    __metadata("design:type", Function)
], LabelsComponent.prototype, "content", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], LabelsComponent.prototype, "visible", void 0);

/**
 * @hidden
 */
class RangeComponent extends CollectionItemComponent {
}
__decorate([
    Input(),
    __metadata("design:type", Number)
], RangeComponent.prototype, "from", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], RangeComponent.prototype, "to", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], RangeComponent.prototype, "opacity", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], RangeComponent.prototype, "color", void 0);

/**
 * @hidden
 */
class ScaleComponent extends SettingsComponent {
}
__decorate([
    Input(),
    __metadata("design:type", Object)
], ScaleComponent.prototype, "labels", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ScaleComponent.prototype, "majorTicks", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ScaleComponent.prototype, "minorTicks", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ScaleComponent.prototype, "min", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ScaleComponent.prototype, "max", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ScaleComponent.prototype, "minorUnit", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ScaleComponent.prototype, "majorUnit", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], ScaleComponent.prototype, "reverse", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ScaleComponent.prototype, "rangeSize", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ScaleComponent.prototype, "rangePlaceholderColor", void 0);

/**
 * A directive that selects a [template]({{ site.data.urls.angular['templatesyntax'] }})
 * within the `<kendo-arcgauge>` component which will be used for the center template
 * ([more information and example]({% slug centertemplate_arcgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-arcgauge [value]="value">
 *             <ng-template kendoArcGaugeCenterTemplate let-value="value">
 *                 {{ value }}%
 *             </ng-template>
 *         </kendo-arcgauge>
 *
 *     `
 * })
 * export class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
let ArcCenterTemplateDirective = class ArcCenterTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
ArcCenterTemplateDirective = __decorate([
    Directive({
        selector: '[kendoArcGaugeCenterTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], ArcCenterTemplateDirective);

/* tslint:disable:use-life-cycle-interface */
/**
 * Represents the [Kendo UI ArcGauge component for Angular]({% slug overview_arcgauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-arcgauge [value]="value" [scale]="{ max: 100 }">
 *          <ng-template kendoArcGaugeCenterTemplate let-value="value">
 *              {{ value }}%
 *          </ng-template>
 *      </kendo-arcgauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
let ArcGaugeComponent = class ArcGaugeComponent extends GaugeComponent {
    constructor(changeDetector, configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
        super(configurationService, themeService, intlService, localizationService, element, renderer, ngZone);
        this.changeDetector = changeDetector;
        this.className = true;
        this.centerTemplateContext = {};
    }
    ngOnInit() {
        super.ngOnInit();
        if (this.element) {
            this.renderer.setStyle(this.element.nativeElement, 'position', 'relative');
        }
    }
    ngAfterViewChecked() {
        super.ngAfterViewChecked();
        if (this.labelElement && !this.centerTemplate) {
            this.changeDetector.detectChanges();
        }
        else if (!this.labelElement && this.centerTemplate) {
            this.updateCenterTemplate();
        }
    }
    /**
     * Exports the gauge as a Drawing `Scene`.
     *
     * @returns {Promise<Group>} - A promise that will be resolved with the export visual.
     */
    exportVisual() {
        return drawDOM(this.element.nativeElement);
    }
    /**
     * Detects the size of the container and redraws the Gauge.
     * Resizing is automatic unless you set the `resizeRateLimit` option to `0`.
     */
    resize() {
        super.resize();
        this.updateCenterTemplate();
    }
    createInstance(element, options, theme, context) {
        this.instance = new ArcGauge(element, options, theme, context);
        this.updateElements();
    }
    updateOptions() {
        super.updateOptions();
        this.updateElements();
    }
    setValues() {
        const value = this.configurationService.readValue();
        this.instance.value(value);
        this.updateCenterTemplate();
    }
    updateElements() {
        this.resizeSensor.acceptSize();
        this.updateCenterTemplate();
    }
    updateCenterTemplate() {
        if (!this.instance || !this.centerTemplate) {
            return;
        }
        this.centerTemplateContext.value = this.instance.value();
        this.centerTemplateContext.color = this.instance.currentColor();
        this.changeDetector.detectChanges();
        this.positionLabel();
    }
    positionLabel() {
        if (!this.labelElement) {
            return;
        }
        const element = this.labelElement.nativeElement;
        const width = element.offsetWidth;
        const height = element.offsetHeight;
        const position = this.instance.centerLabelPosition(width, height);
        element.style.top = `${position.top}px`;
        element.style.left = `${position.left}px`;
    }
    //tslint:disable-next-line: no-empty
    updateSize() {
    }
};
__decorate([
    Input(),
    __metadata("design:type", Number)
], ArcGaugeComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ArcGaugeComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], ArcGaugeComponent.prototype, "colors", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ArcGaugeComponent.prototype, "opacity", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], ArcGaugeComponent.prototype, "scale", void 0);
__decorate([
    ContentChild(ArcCenterTemplateDirective, { static: false }),
    __metadata("design:type", ArcCenterTemplateDirective)
], ArcGaugeComponent.prototype, "centerTemplate", void 0);
__decorate([
    ViewChild("label", { static: false }),
    __metadata("design:type", ElementRef)
], ArcGaugeComponent.prototype, "labelElement", void 0);
__decorate([
    HostBinding('class.k-arcgauge'),
    __metadata("design:type", Boolean)
], ArcGaugeComponent.prototype, "className", void 0);
ArcGaugeComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        exportAs: 'kendoArcGauge',
        providers: [
            ConfigurationService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.arcgauge'
            }
        ],
        selector: 'kendo-arcgauge',
        template: `
    <div #surface class='k-chart-surface'></div>
    <div class="k-arcgauge-label" *ngIf="centerTemplate" #label>
        <ng-template [ngTemplateOutlet]="centerTemplate.templateRef" [ngTemplateOutletContext]="centerTemplateContext"></ng-template>
    </div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `
    }),
    __metadata("design:paramtypes", [ChangeDetectorRef,
        ConfigurationService,
        ThemeService,
        IntlService,
        LocalizationService,
        ElementRef,
        Renderer2,
        NgZone])
], ArcGaugeComponent);

/**
 * The configuration options of the ArcGauge area.
 * Represents the entire visible area of the ArcGauge.
 */
let ArcGaugeAreaComponent = class ArcGaugeAreaComponent extends GaugeAreaComponent {
    constructor(configurationService) {
        super('gaugeArea', configurationService);
        this.configurationService = configurationService;
    }
};
ArcGaugeAreaComponent = __decorate([
    Component({
        selector: 'kendo-arcgauge-area',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], ArcGaugeAreaComponent);

/**
 * The configuration options for the scale of the ArcGauge
 * ([see example]({% slug scaleoptions_arcgauge %})).
 */
let ArcScaleComponent = class ArcScaleComponent extends ScaleComponent {
    constructor(configurationService) {
        super('scale', configurationService);
        this.configurationService = configurationService;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], ArcScaleComponent.prototype, "labels", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ArcScaleComponent.prototype, "rangeDistance", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], ArcScaleComponent.prototype, "rangeLineCap", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ArcScaleComponent.prototype, "startAngle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ArcScaleComponent.prototype, "endAngle", void 0);
ArcScaleComponent = __decorate([
    Component({
        selector: 'kendo-arcgauge-scale',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], ArcScaleComponent);

/**
 * The configuration options for the scale labels of the RadialGauge.
 */
let RadialLabelsComponent = class RadialLabelsComponent extends LabelsComponent {
    constructor(configurationService) {
        super('scale.labels', configurationService);
        this.configurationService = configurationService;
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], RadialLabelsComponent.prototype, "position", void 0);
RadialLabelsComponent = __decorate([
    Component({
        selector: 'kendo-radialgauge-scale-labels',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], RadialLabelsComponent);

/**
 * The configuration options for the scale labels of the ArcGauge.
 */
let ArcLabelsComponent = class ArcLabelsComponent extends RadialLabelsComponent {
    constructor(configurationService) {
        super(configurationService);
        this.configurationService = configurationService;
        configurationService.set(`${this.key}.visible`, true);
    }
};
ArcLabelsComponent = __decorate([
    Component({
        selector: 'kendo-arcgauge-scale-labels',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], ArcLabelsComponent);

/**
 * The configuration options for an ArcGauge color item.
 */
let ColorComponent = class ColorComponent extends CollectionItemComponent {
    constructor(configurationService, collectionChangesService) {
        super(configurationService, collectionChangesService);
    }
};
__decorate([
    Input(),
    __metadata("design:type", String)
], ColorComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ColorComponent.prototype, "opacity", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ColorComponent.prototype, "from", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], ColorComponent.prototype, "to", void 0);
ColorComponent = __decorate([
    Component({
        providers: [ConfigurationService],
        selector: 'kendo-arcgauge-color',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], ColorComponent);

/**
 * A collection of one or more ArcGauge colors
 * ([more information and example]({% slug colorranges_arcgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-arcgauge [value]="value">
 *              <kendo-arcgauge-colors>
 *                  <kendo-arcgauge-color *ngFor="let item of colors"
 *                      [from]="item.from" [to]="item.to" [color]="item.color">
 *                  </kendo-arcgauge-color>
 *              </kendo-arcgauge-colors>
 *         </kendo-arcgauge>
 *     `
 * })
 * export class AppComponent {
 *     public value: number = 10;
 *
 *     public colors: any[] = [{
 *         to: 25,
 *         color: '#0058e9'
 *     }, {
 *         from: 25,
 *         to: 50,
 *         color: '#37b400'
 *     }, {
 *         from: 50,
 *         to: 75,
 *         color: '#ffc000'
 *     }, {
 *         from: 75,
 *         color: '#f31700'
 *     }];
 * }
 *
 * ```
 */
let ColorsComponent = class ColorsComponent extends CollectionComponent {
    constructor(configurationService, collectionChangesService) {
        super('colors', configurationService, collectionChangesService);
    }
};
__decorate([
    ContentChildren(ColorComponent),
    __metadata("design:type", QueryList)
], ColorsComponent.prototype, "children", void 0);
ColorsComponent = __decorate([
    Component({
        providers: [CollectionChangesService],
        selector: 'kendo-arcgauge-colors',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], ColorsComponent);

const DIRECTIVES = [ArcGaugeComponent, ArcCenterTemplateDirective, ArcGaugeAreaComponent, ArcScaleComponent, ArcLabelsComponent,
    ColorsComponent, ColorComponent];
/**
 * A [module]({{ site.data.urls.angular['ngmodules'] }}) that includes the ArcGauge component and its directives.
 * Imports the `ArcGaugeModule` into your application
 * [root module]({{ site.data.urls.angular['ngmodules'] }}#angular-modularity) or any other sub-module
 * that will use the ArcGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { ArcGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, ArcGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
let ArcGaugeModule = class ArcGaugeModule {
};
ArcGaugeModule = __decorate([
    NgModule({
        declarations: [DIRECTIVES],
        exports: [DIRECTIVES],
        imports: [SharedModule, CommonModule]
    })
], ArcGaugeModule);

/**
 * Represents the [Kendo UI LinearGauge component for Angular]({% slug overview_lineargauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-lineargauge [pointer]="{ value: value }">
 *      </kendo-lineargauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
let LinearGaugeComponent = class LinearGaugeComponent extends GaugeComponent {
    constructor(configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
        super(configurationService, themeService, intlService, localizationService, element, renderer, ngZone);
    }
    createInstance(element, options, theme, context) {
        this.instance = new LinearGauge(element, options, theme, context);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], LinearGaugeComponent.prototype, "pointer", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LinearGaugeComponent.prototype, "scale", void 0);
LinearGaugeComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        exportAs: 'kendoLinearGauge',
        providers: [
            ConfigurationService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.lineargauge'
            }
        ],
        selector: 'kendo-lineargauge',
        template: `
    <div #surface class='k-chart-surface'></div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        ThemeService,
        IntlService,
        LocalizationService,
        ElementRef,
        Renderer2,
        NgZone])
], LinearGaugeComponent);

/**
 * The configuration options for the LinearGauge area.
 * Represents the entire visible area of the LinearGauge.
 */
let LinearGaugeAreaComponent = class LinearGaugeAreaComponent extends GaugeAreaComponent {
    constructor(configurationService) {
        super('gaugeArea', configurationService);
        this.configurationService = configurationService;
    }
};
LinearGaugeAreaComponent = __decorate([
    Component({
        selector: 'kendo-lineargauge-area',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], LinearGaugeAreaComponent);

/**
 * The configuration options for the scale of the LinearGauge
 * ([see example]({% slug scaleoptions_lineargauge %})).
 */
let LinearScaleComponent = class LinearScaleComponent extends ScaleComponent {
    constructor(configurationService) {
        super('scale', configurationService);
        this.configurationService = configurationService;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], LinearScaleComponent.prototype, "line", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], LinearScaleComponent.prototype, "ranges", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], LinearScaleComponent.prototype, "mirror", void 0);
__decorate([
    Input(),
    __metadata("design:type", Boolean)
], LinearScaleComponent.prototype, "vertical", void 0);
LinearScaleComponent = __decorate([
    Component({
        selector: 'kendo-lineargauge-scale',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], LinearScaleComponent);

/**
 * The configuration options for the scale labels of the LinearGauge.
 */
let LinearLabelsComponent = class LinearLabelsComponent extends LabelsComponent {
    constructor(configurationService) {
        super('scale.labels', configurationService);
        this.configurationService = configurationService;
    }
};
LinearLabelsComponent = __decorate([
    Component({
        selector: 'kendo-lineargauge-scale-labels',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], LinearLabelsComponent);

/**
 * The configuration options for a pointer item of a LinearGauge.
 */
let LinearPointerComponent = class LinearPointerComponent extends CollectionItemComponent {
    constructor(configurationService, collectionChangesService) {
        super(configurationService, collectionChangesService);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], LinearPointerComponent.prototype, "border", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LinearPointerComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LinearPointerComponent.prototype, "margin", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], LinearPointerComponent.prototype, "opacity", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LinearPointerComponent.prototype, "shape", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], LinearPointerComponent.prototype, "size", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], LinearPointerComponent.prototype, "value", void 0);
LinearPointerComponent = __decorate([
    Component({
        providers: [ConfigurationService],
        selector: 'kendo-lineargauge-pointer',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], LinearPointerComponent);

/**
 * A collection of one or more LinearGauge pointers
 * ([more information]({% slug multiplepointers_lineargauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-lineargauge>
 *             <kendo-lineargauge-pointers>
 *                 <kendo-lineargauge-pointer *ngFor="let pointer of pointers"
 *                     [value]="pointer.value" [color]="pointer.color" shape="barIndicator">
 *                 </kendo-lineargauge-pointer>
 *             </kendo-lineargauge-pointers>
 *         </kendo-lineargauge>
 *     `
 * })
 * export class AppComponent {
 *     public pointers: any[] = [{
 *         value: 10,
 *         color: '#ff4500'
 *     }, {
 *         value: 12,
 *         color: '#28b4c8'
 *     }, {
 *         value: 20,
 *         color: '#8b0000'
 *     }];
 * }
 *
 * ```
 */
let LinearPointersComponent = class LinearPointersComponent extends CollectionComponent {
    constructor(configurationService, collectionChangesService) {
        super('pointer', configurationService, collectionChangesService);
    }
};
__decorate([
    ContentChildren(LinearPointerComponent),
    __metadata("design:type", QueryList)
], LinearPointersComponent.prototype, "children", void 0);
LinearPointersComponent = __decorate([
    Component({
        providers: [CollectionChangesService],
        selector: 'kendo-lineargauge-pointers',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], LinearPointersComponent);

/**
 * The configuration options for a scale range item of a LinearGauge.
 */
let LinearRangeComponent = class LinearRangeComponent extends RangeComponent {
    constructor(configurationService, collectionChangesService) {
        super(configurationService, collectionChangesService);
    }
};
LinearRangeComponent = __decorate([
    Component({
        providers: [ConfigurationService],
        selector: 'kendo-lineargauge-scale-range',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], LinearRangeComponent);

/**
 * A collection of one or more LinearGauge scale ranges
 * ([more information and example]({% slug scaleranghes_lineargauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-lineargauge>
 *             <kendo-lineargauge-scale>
 *                 <kendo-lineargauge-scale-ranges>
 *                     <kendo-lineargauge-scale-range *ngFor="let range of ranges"
 *                         [from]="range.from" [to]="range.to" [color]="range.color">
 *                     </kendo-lineargauge-scale-range>
 *                 </kendo-lineargauge-scale-ranges>
 *             </kendo-lineargauge-scale>
 *         </kendo-lineargauge>
 *     `
 * })
 * export class AppComponent {
 *     public ranges: any[] = [{
 *         from: 0,
 *         to: 15,
 *         color: '#ffd246'
 *     }, {
 *         from: 15,
 *         to: 30,
 *         color: '#28b4c8'
 *     }, {
 *         from: 30,
 *         to: 50,
 *         color: '#78d237'
 *     }];
 * }
 *
 * ```
 */
let LinearRangesComponent = class LinearRangesComponent extends CollectionComponent {
    constructor(configurationService, collectionChangesService) {
        super('scale.ranges', configurationService, collectionChangesService);
    }
};
__decorate([
    ContentChildren(LinearRangeComponent),
    __metadata("design:type", QueryList)
], LinearRangesComponent.prototype, "children", void 0);
LinearRangesComponent = __decorate([
    Component({
        providers: [CollectionChangesService],
        selector: 'kendo-lineargauge-scale-ranges',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], LinearRangesComponent);

const DIRECTIVES$1 = [LinearGaugeComponent, LinearGaugeAreaComponent, LinearScaleComponent, LinearLabelsComponent,
    LinearPointersComponent, LinearPointerComponent, LinearRangeComponent, LinearRangesComponent];
/**
 * A [module]({{ site.data.urls.angular['ngmodules'] }}) that includes the LinearGauge component and its directives.
 * Imports the `LinearGaugeModule` into your application
 * [root module]({{ site.data.urls.angular['ngmodules'] }}#angular-modularity) or any other sub-module
 * that will use the LinearGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { LinearGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, LinearGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
let LinearGaugeModule = class LinearGaugeModule {
};
LinearGaugeModule = __decorate([
    NgModule({
        declarations: [DIRECTIVES$1],
        exports: [DIRECTIVES$1],
        imports: [SharedModule]
    })
], LinearGaugeModule);

/**
 * Represents the [Kendo UI RadialGauge component for Angular]({% slug overview_radialgauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-radialgauge [pointer]="{ value: value }">
 *      </kendo-radialgauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
let RadialGaugeComponent = class RadialGaugeComponent extends GaugeComponent {
    constructor(configurationService, themeService, intlService, localizationService, element, renderer, ngZone) {
        super(configurationService, themeService, intlService, localizationService, element, renderer, ngZone);
    }
    createInstance(element, options, theme, context) {
        this.instance = new RadialGauge(element, options, theme, context);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], RadialGaugeComponent.prototype, "pointer", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], RadialGaugeComponent.prototype, "scale", void 0);
RadialGaugeComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        exportAs: 'kendoRadialGauge',
        providers: [
            ConfigurationService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.radialgauge'
            }
        ],
        selector: 'kendo-radialgauge',
        template: `
    <div #surface class='k-chart-surface'></div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        ThemeService,
        IntlService,
        LocalizationService,
        ElementRef,
        Renderer2,
        NgZone])
], RadialGaugeComponent);

/**
 * The configuration options for the RadialGauge area.
 * Represents the entire visible area of the RadialGauge.
 */
let RadialGaugeAreaComponent = class RadialGaugeAreaComponent extends GaugeAreaComponent {
    constructor(configurationService) {
        super('gaugeArea', configurationService);
        this.configurationService = configurationService;
    }
};
RadialGaugeAreaComponent = __decorate([
    Component({
        selector: 'kendo-radialgauge-area',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], RadialGaugeAreaComponent);

/**
 * The configuration options for the scale of the RadialGauge
 * ([more information and example]({% slug scaleoptions_radialgauge %})).
 */
let RadialScaleComponent = class RadialScaleComponent extends ScaleComponent {
    constructor(configurationService) {
        super('scale', configurationService);
        this.configurationService = configurationService;
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], RadialScaleComponent.prototype, "labels", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], RadialScaleComponent.prototype, "rangeDistance", void 0);
__decorate([
    Input(),
    __metadata("design:type", Array)
], RadialScaleComponent.prototype, "ranges", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], RadialScaleComponent.prototype, "startAngle", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], RadialScaleComponent.prototype, "endAngle", void 0);
RadialScaleComponent = __decorate([
    Component({
        selector: 'kendo-radialgauge-scale',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService])
], RadialScaleComponent);

/**
 * The configuration options for a pointer item of a RadialGauge.
 */
let RadialPointerComponent = class RadialPointerComponent extends CollectionItemComponent {
    constructor(configurationService, collectionChangesService) {
        super(configurationService, collectionChangesService);
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], RadialPointerComponent.prototype, "cap", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], RadialPointerComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], RadialPointerComponent.prototype, "length", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], RadialPointerComponent.prototype, "value", void 0);
RadialPointerComponent = __decorate([
    Component({
        providers: [ConfigurationService],
        selector: 'kendo-radialgauge-pointer',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], RadialPointerComponent);

/**
 * A collection of one or more RadialGauge pointers
 * ([more information and example]({% slug multiplepointers_radialgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-radialgauge>
 *             <kendo-radialgauge-pointers>
 *                 <kendo-radialgauge-pointer *ngFor="let pointer of pointers"
 *                     [value]="pointer.value" [color]="pointer.color">
 *                 </kendo-radialgauge-pointer>
 *             </kendo-radialgauge-pointers>
 *         </kendo-radialgauge>
 *     `
 * })
 * export class AppComponent {
 *     public pointers: any[] = [{
 *         value: 10,
 *         color: '#ffd246'
 *     }, {
 *         value: 20,
 *         color: '#28b4c8'
 *     }, {
 *         value: 30,
 *         color: '#78d237'
 *     }];
 * }
 *
 * ```
 */
let RadialPointersComponent = class RadialPointersComponent extends CollectionComponent {
    constructor(configurationService, collectionChangesService) {
        super('pointer', configurationService, collectionChangesService);
    }
};
__decorate([
    ContentChildren(RadialPointerComponent),
    __metadata("design:type", QueryList)
], RadialPointersComponent.prototype, "children", void 0);
RadialPointersComponent = __decorate([
    Component({
        providers: [CollectionChangesService],
        selector: 'kendo-radialgauge-pointers',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], RadialPointersComponent);

/**
 * The configuration options for a scale range item of a RadialGauge.
 */
let RadialRangeComponent = class RadialRangeComponent extends RangeComponent {
    constructor(configurationService, collectionChangesService) {
        super(configurationService, collectionChangesService);
    }
};
RadialRangeComponent = __decorate([
    Component({
        providers: [ConfigurationService],
        selector: 'kendo-radialgauge-scale-range',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], RadialRangeComponent);

/**
 * A collection of one or more RadialGauge scale ranges
 * ([more information and example]({% slug scaleranghes_radialgauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-radialgauge>
 *             <kendo-radialgauge-scale>
 *                 <kendo-radialgauge-scale-ranges>
 *                     <kendo-radialgauge-scale-range *ngFor="let range of ranges"
 *                         [from]="range.from" [to]="range.to" [color]="range.color">
 *                     </kendo-radialgauge-scale-range>
 *                 </kendo-radialgauge-scale-ranges>
 *             </kendo-radialgauge-scale>
 *         </kendo-radialgauge>
 *     `
 * })
 * export class AppComponent {
 *     public ranges: any[] = [{
 *         from: 0,
 *         to: 15,
 *         color: '#ffd246'
 *     }, {
 *         from: 15,
 *         to: 30,
 *         color: '#28b4c8'
 *     }, {
 *         from: 30,
 *         to: 50,
 *         color: '#78d237'
 *     }];
 * }
 *
 * ```
 */
let RadialRangesComponent = class RadialRangesComponent extends CollectionComponent {
    constructor(configurationService, collectionChangesService) {
        super('scale.ranges', configurationService, collectionChangesService);
    }
};
__decorate([
    ContentChildren(RadialRangeComponent),
    __metadata("design:type", QueryList)
], RadialRangesComponent.prototype, "children", void 0);
RadialRangesComponent = __decorate([
    Component({
        providers: [CollectionChangesService],
        selector: 'kendo-radialgauge-scale-ranges',
        template: ''
    }),
    __metadata("design:paramtypes", [ConfigurationService,
        CollectionChangesService])
], RadialRangesComponent);

const DIRECTIVES$2 = [RadialGaugeComponent, RadialGaugeAreaComponent, RadialScaleComponent, RadialLabelsComponent,
    RadialPointersComponent, RadialPointerComponent, RadialRangeComponent, RadialRangesComponent];
/**
 * A [module]({{ site.data.urls.angular['ngmodules'] }}) that includes the RadialGauge component and its directives.
 * Imports the `RadialGaugeModule` into your application
 * [root module]({{ site.data.urls.angular['ngmodules'] }}#angular-modularity) or any other sub-module
 * that will use the RadialGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { RadialGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, RadialGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
let RadialGaugeModule = class RadialGaugeModule {
};
RadialGaugeModule = __decorate([
    NgModule({
        declarations: [DIRECTIVES$2],
        exports: [DIRECTIVES$2],
        imports: [SharedModule]
    })
], RadialGaugeModule);

/**
 * A directive that selects a [template]({{ site.data.urls.angular['templatesyntax'] }})
 * within the `<kendo-circulargauge>` component which will be used for the center template
 * ([more information and example]({% slug centertemplate_circulargauge %})).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *         <kendo-circulargauge [value]="value">
 *             <ng-template kendoCircularGaugeCenterTemplate let-value="value">
 *                 {{ value }}%
 *             </ng-template>
 *         </kendo-circulargauge>
 *
 *     `
 * })
 * export class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
let CircularGaugeCenterTemplateDirective = class CircularGaugeCenterTemplateDirective {
    constructor(templateRef) {
        this.templateRef = templateRef;
    }
};
CircularGaugeCenterTemplateDirective = __decorate([
    Directive({
        selector: '[kendoCircularGaugeCenterTemplate]'
    }),
    __param(0, Optional()),
    __metadata("design:paramtypes", [TemplateRef])
], CircularGaugeCenterTemplateDirective);

/**
 * Represents the [Kendo UI CircularGauge component for Angular]({% slug overview_circulargauge_gauges %}).
 *
 * @example
 * ```ts
 * import { Component } from '@angular/core';
 *
 * _@Component({
 *   selector: 'my-app',
 *   template: `
 *      <kendo-circulargauge [value]="value" [scale]="{ max: 100 }">
 *          <ng-template kendoCircularGaugeCenterTemplate let-value="value">
 *              {{ value }}%
 *          </ng-template>
 *      </kendo-circulargauge>
 *   `
 * })
 * class AppComponent {
 *   public value: number = 10;
 * }
 *
 * ```
 */
let CircularGaugeComponent = class CircularGaugeComponent extends ArcGaugeComponent {
    createInstance(element, options, theme, context) {
        this.instance = new CircularGauge(element, options, theme, context);
        this.updateElements();
    }
};
__decorate([
    Input(),
    __metadata("design:type", Object)
], CircularGaugeComponent.prototype, "scale", void 0);
__decorate([
    ContentChild(CircularGaugeCenterTemplateDirective, { static: false }),
    __metadata("design:type", CircularGaugeCenterTemplateDirective)
], CircularGaugeComponent.prototype, "centerTemplate", void 0);
CircularGaugeComponent = __decorate([
    Component({
        changeDetection: ChangeDetectionStrategy.OnPush,
        exportAs: 'kendoCircularGauge',
        providers: [
            ConfigurationService,
            LocalizationService,
            {
                provide: L10N_PREFIX,
                useValue: 'kendo.circulargauge'
            }
        ],
        selector: 'kendo-circulargauge',
        template: `
    <div #surface class='k-chart-surface'></div>
    <div class="k-arcgauge-label" *ngIf="centerTemplate" #label>
        <ng-template [ngTemplateOutlet]="centerTemplate.templateRef" [ngTemplateOutletContext]="centerTemplateContext"></ng-template>
    </div>
    <kendo-resize-sensor (resize)="onResize($event)" [rateLimit]="resizeRateLimit"></kendo-resize-sensor>
  `
    })
], CircularGaugeComponent);

/**
 * The configuration options of the Circular Gauge area.
 * Represents the entire visible area of the Circular Gauge.
 */
let CircularGaugeAreaComponent = class CircularGaugeAreaComponent extends ArcGaugeAreaComponent {
};
CircularGaugeAreaComponent = __decorate([
    Component({
        selector: 'kendo-circulargauge-area',
        template: ''
    })
], CircularGaugeAreaComponent);

/**
 * The configuration options for the scale of the Circular Gauge
 * ([see example]({% slug scaleoptions_circulargauge %})).
 */
let CircularGaugeScaleComponent = class CircularGaugeScaleComponent extends ArcScaleComponent {
};
CircularGaugeScaleComponent = __decorate([
    Component({
        selector: 'kendo-circulargauge-scale',
        template: ''
    })
], CircularGaugeScaleComponent);

/**
 * The configuration options for the scale labels of the Circular Gauge.
 */
let CircularGaugeLabelsComponent = class CircularGaugeLabelsComponent extends ArcLabelsComponent {
};
CircularGaugeLabelsComponent = __decorate([
    Component({
        selector: 'kendo-circulargauge-scale-labels',
        template: ''
    })
], CircularGaugeLabelsComponent);

const DIRECTIVES$3 = [
    CircularGaugeComponent,
    CircularGaugeCenterTemplateDirective,
    CircularGaugeAreaComponent,
    CircularGaugeScaleComponent,
    CircularGaugeLabelsComponent
];
/**
 * A [module]({{ site.data.urls.angular['ngmodules'] }}) that includes the CircularGauge component and its directives.
 * Imports the `CircularGaugeModule` into your application
 * [root module]({{ site.data.urls.angular['ngmodules'] }}#angular-modularity) or any other sub-module
 * that will use the CircularGauge component.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { CircularGaugeModule } from '@progress/kendo-angular-gauges';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, CircularGaugeModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
let CircularGaugeModule = class CircularGaugeModule {
};
CircularGaugeModule = __decorate([
    NgModule({
        declarations: [DIRECTIVES$3],
        exports: [DIRECTIVES$3],
        imports: [SharedModule, CommonModule]
    })
], CircularGaugeModule);

/**
 * A [module]({{ site.data.urls.angular['ngmodules'] }}) that includes all Gauge components and directives.
 *
 * Imports the `GaugesModule` into your application
 * [root module]({{ site.data.urls.angular['ngmodules'] }}#angular-modularity) or any other sub-module
 * that will use the Gauge components.
 *
 * @example
 * ```ts-no-run
 * import { NgModule } from '@angular/core';
 * import { BrowserModule } from '@angular/platform-browser';
 * import { GaugesModule } from '@progress/kendo-angular-charts';
 * import { AppComponent } from './app.component';
 *
 * _@NgModule({
 *     bootstrap:    [AppComponent],
 *     declarations: [AppComponent],
 *     imports:      [BrowserModule, GaugesModule]
 * })
 * export class AppModule {
 * }
 * ```
 */
let GaugesModule = class GaugesModule {
};
GaugesModule = __decorate([
    NgModule({
        exports: [ArcGaugeModule, CircularGaugeModule, LinearGaugeModule, RadialGaugeModule]
    })
], GaugesModule);

/**
 * Generated bundle index. Do not edit.
 */

export { CircularGaugeCenterTemplateDirective, CircularGaugeComponent, CircularGaugeModule, CircularGaugeAreaComponent, CircularGaugeLabelsComponent, CircularGaugeScaleComponent, SharedModule, ArcGaugeModule, LinearGaugeModule, RadialGaugeModule, GaugesModule, CollectionComponent, CollectionItemComponent, GaugeComponent, GaugeAreaComponent, LabelsComponent, RangeComponent, ScaleComponent, SettingsComponent, ConfigurationService, CollectionChangesService, ThemeService, ArcGaugeComponent, ArcGaugeAreaComponent, ArcScaleComponent, ArcLabelsComponent, ArcCenterTemplateDirective, ColorsComponent, ColorComponent, LinearGaugeComponent, LinearGaugeAreaComponent, LinearScaleComponent, LinearLabelsComponent, LinearPointersComponent, LinearPointerComponent, LinearRangeComponent, LinearRangesComponent, RadialGaugeComponent, RadialGaugeAreaComponent, RadialScaleComponent, RadialLabelsComponent, RadialPointersComponent, RadialPointerComponent, RadialRangeComponent, RadialRangesComponent };
